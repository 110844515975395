import "./imageArticleSection.scss";
import React from "react";
import {Media} from "reactstrap";

export interface ImageArticleSectionProps {
    src: string,
    children?: React.ReactNode,
    color?: string,
}

export const ImageArticleSection = (props: ImageArticleSectionProps) => {
    const {src, children, color = ""} = props;
    return (
        <div className={`image-article-section ${color}`}>
            <div className={"image-wrapper"}>
                <Media src={src}/>
            </div>
            <div className={"contents-wrapper"}>
                <div className={"article-contents"}>
                    {children}
                </div>
            </div>
        </div>
    )
}

import "./index.scss";
import * as React from 'react'
import {PageProps} from "gatsby";
import {useTranslation} from "react-i18next";
import Layout from "../layouts";
import {Banner} from "../components/Banner";
import {Media} from 'reactstrap';
import {MainContainer} from "../components/MainContainer";
import {ArticleSection} from "../components/ArticleSection";
import {ArticleContainer} from "../components/ArticleContainer";
import {TileContainer} from "../components/TileContainer";
import {PageSection} from "../components/PageSection";
import {ImageArticleSection} from "../components/ImageArticleSection";
import {useMediaQuery} from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function IndexPage(props: PageProps) {
    const {location} = props;
    const {t} = useTranslation("index");

    const isSmall = useMediaQuery({maxWidth: "576px"})

    return (
        <Layout location={location}>
            <Banner background={"main"}>
                <Media className={"logo"}/>
                <div className={"banner-text"}>
                    <h4>{t("bannerHeader", "Unparalleled staffing solutions | Exceptional level of service")}</h4>
                </div>
            </Banner>
            <MainContainer>
                <ArticleContainer>
                    <PageSection>
                        <div className={"tile-section"}>
                            <ArticleSection className={"sm"} color={"gold"}>
                                <TileContainer color={"gold"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={"user-tie"}/></div>
                                    <h5>{t("globalWorkforceHead", "")}</h5>
                                    <p>{t("globalWorkforceContent", "")}</p>
                                </TileContainer>
                            </ArticleSection>
                            <ArticleSection className={"sm"} color={"green"}>
                                <TileContainer color={"green"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={"clipboard-list"}/></div>
                                    <h5>{t("externalAdminHead", "")}</h5>
                                    <p>{t("externalAdminContent", "")}</p>
                                </TileContainer>
                            </ArticleSection>
                            <ArticleSection className={"sm"} color={"blue"}>
                                <TileContainer color={"green"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={["far", "clock"]}/></div>
                                    <h5>{t("businessSupportHead", "")}</h5>
                                    <p>{t("businessSupportContent", "")}</p>
                                </TileContainer>
                            </ArticleSection>
                            <ArticleSection className={"sm"} color={"pink"}>
                                <TileContainer color={"green"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={"hands-helping"}/></div>
                                    <h5>{t("employeeSupportHead", "")}</h5>
                                    <p>{t("employeeSupportContent", "")}</p>
                                </TileContainer>
                            </ArticleSection>
                        </div>
                    </PageSection>
                    <ImageArticleSection src={isSmall ? "/eu_200x200.jpg" : "/eu_400x400.jpg"} color={"blue"}>
                        <h3>{t("mainHeader", "")}</h3>
                        <p>{t("mainParagraph1", "")}</p>
                        <p>{t("mainParagraph2", "")}</p>
                        <p>{t("mainParagraph3", "")}</p>
                    </ImageArticleSection>

                </ArticleContainer>
            </MainContainer>
        </Layout>
    )
}
